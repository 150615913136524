import { WalletProvider } from '@tronweb3/tronwallet-adapter-react-hooks';
import {
    WalletDisconnectedError,
    WalletError,
    WalletNotFoundError
} from '@tronweb3/tronwallet-abstract-adapter';
import {
    TronLinkAdapter,
    BitKeepAdapter,
    OkxWalletAdapter,
    TokenPocketAdapter
} from '@tronweb3/tronwallet-adapters';
import { useMemo, useEffect } from 'react';
import { initTronWeb } from './tronWebInit';

const WalletProviderWrapper = ({ children }) => {
    // Initialize TronWeb on component mount
    useEffect(() => {
        const init = async () => {
            try {
                await initTronWeb();
            } catch (error) {
                console.error('Failed to initialize TronWeb:', error);
            }
        };
        init();
    }, []);

    // Initialize adapters
    const adapters = useMemo(() => {
        const tronLink = new TronLinkAdapter();
        const bitKeep = new BitKeepAdapter();
        const okxWallet = new OkxWalletAdapter();
        const tokenPocket = new TokenPocketAdapter();

        return [
            tronLink,
            bitKeep,
            okxWallet,
            tokenPocket
        ];
    }, []);

    // Handle wallet errors
    const onError = (error) => {
        if (error instanceof WalletNotFoundError) {
            console.error('Wallet not found');
        } else if (error instanceof WalletDisconnectedError) {
            console.error('Wallet disconnected');
        } else if (error instanceof WalletError) {
            console.error('Wallet error:', error.message);
        } else {
            console.error('Unknown error:', error);
        }
    };

    return (
        <WalletProvider
            adapters={adapters}
            onError={onError}
            autoConnect={false} // Changed to false to ensure proper initialization
        >
            {children}
        </WalletProvider>
    );
};

export default WalletProviderWrapper;
// // WalletProvider.jsx
// import { WalletProvider } from '@tronweb3/tronwallet-adapter-react-hooks';
// import {
//     WalletDisconnectedError,
//     WalletError,
//     WalletNotFoundError
// } from '@tronweb3/tronwallet-abstract-adapter';
// import {
//     TronLinkAdapter,
//     BitKeepAdapter,
//     OkxWalletAdapter,
//     TokenPocketAdapter
// } from '@tronweb3/tronwallet-adapters';
// import { useMemo } from 'react';

// const WalletProviderWrapper = ({ children }) => {
//     // Initialize adapters
//     const adapters = useMemo(() => {
//         const tronLink = new TronLinkAdapter();
//         const bitKeep = new BitKeepAdapter();
//         const okxWallet = new OkxWalletAdapter();
//         const tokenPocket = new TokenPocketAdapter();

//         return [
//             tronLink,
//             bitKeep,
//             okxWallet,
//             tokenPocket
//         ];
//     }, []);

//     // Handle wallet errors
//     const onError = (error) => {
//         if (error instanceof WalletNotFoundError) {
//             console.error('Wallet not found');
//         } else if (error instanceof WalletDisconnectedError) {
//             console.error('Wallet disconnected');
//         } else if (error instanceof WalletError) {
//             console.error('Wallet error:', error.message);
//         } else {
//             console.error('Unknown error:', error);
//         }
//     };

//     return (
//         <WalletProvider
//             adapters={adapters}
//             onError={onError}
//             autoConnect={true}
//         >
//             {children}
//         </WalletProvider>
//     );
// };

// export default WalletProviderWrapper;