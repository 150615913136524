import React, { useEffect, useState } from "react";
import logo from "./../images/logo.svg";
import usdt from "./../images/usdt.png";

import { Input } from "../components/Input";
import { HelpIcon, InfoIcon, LogoutIcon } from "../assets";
import { MiddleTruncate } from "../components/Header";

import WalletModal from "../components/WalletModal";
import { useWalletMetaMask } from "../hooks/useWallet";
import { useContract } from "../hooks/useContract";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../utils/constants";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount, useBalance, useClient, useConnect, useDisconnect, useSwitchChain } from "wagmi";
import { switchNetwork } from '@wagmi/core'
import { baseSepolia, bscTestnet, mainnet, sepolia } from "wagmi/chains";

export default function LandingPage() {
  const {
    connected,
    address,
    disconnect,
  } = useWallet();

  const { connect, connectors } = useConnect();
  const { isConnected, address: wallet_address, connector } = useAccount();
  const { disconnect: disconnectWalletConnect } = useDisconnect();

  const currentBalanceBSC = useBalance({ address: wallet_address, token: "0x96c8Ed4C38E790048502376b253dd8F7B32C75dD" })?.data;
  const currentBalanceETH = useBalance({ address: wallet_address, token: "0xEB2DbE23F0c18C5c78762e74ecF634e54D3BFb04" })?.data;
  const { switchChain } = useSwitchChain();

  const [network, setNetwork] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [proof, setProof] = useState(null);

  const [data, setData] = useState({
    totalCapUsdt: 0,
    totalRaisedUsdt: 0,
    totalCapWow: 0,
    totalRaisedWow: 0,
    minimumPurchaseUsdt: 0,
    tokenPrice: 0,
  });

  useEffect(() => {
    setNetwork(localStorage.getItem("network") || "ethereum");
  }, []);

  const [usdtValue, setUsdtValue] = useState();
  const [usdtBalance, setUsdtBalance] = useState(0);
  const [wowValue, setWowValue] = useState(0);
  const [showWalletModal, setShowWalletModal] = useState(false);
  const [amountInvested, setAmountInvested] = useState({
    usdt: 0,
    wow: 0,
  });
  const [isAddressWhitelisted, setIsAddressWhitelisted] = useState(true);
  const [reloadBalances, setReloadBalances] = useState(false);

  const switchNetworkWalletConnect = () => {
    switchChain({ chainId: network === 'bsc' ? 97 : 84532 });
  }
  useEffect(() => {
    if (wallet_address && (network === 'bsc' || network === 'ethereum')) {
      switchNetworkWalletConnect()
    }
    if (wallet_address && (currentBalanceETH?.formatted || currentBalanceBSC?.formatted)) {
      console.log("connect, connectors", currentBalanceBSC);
      console.log("connect, connectors1", network === 'bsc' ? parseFloat(currentBalanceBSC?.formatted) : parseFloat(currentBalanceETH?.formatted));
      setUsdtBalance(network === 'bsc' ? parseFloat(currentBalanceBSC?.formatted) : parseFloat(currentBalanceETH?.formatted))
    }
  }, [wallet_address, network, currentBalanceETH, currentBalanceBSC])

  const {
    connectMetamask,
    walletAddress,
    disconnectWallet,
    signer,
    switchNetwork,
    setWalletAddress
  } = useWalletMetaMask();
  const { fetchUsdtBalance, buyTokens, addressToUsdt, buyTokenWallet } = useContract(signer);

  useEffect(() => {
    const fetchProof = async () => {
      try {
        let response = await fetch(
          `${API_BASE_URL}/public/proof?address=${walletAddress || wallet_address}`
        );

        response = await response.json();
        if (response.success) {
          setProof(response.proof);
          setIsAddressWhitelisted(true);
        } else {
          setIsAddressWhitelisted(false);
          // setError(response.message);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (walletAddress || wallet_address) {
      fetchProof();
    }
  }, [walletAddress, wallet_address]);

  useEffect(() => {
    const savedNetwork = localStorage.getItem("network");

    if (savedNetwork === "tron" && address) {
      setWalletAddress(address);
    }
  }, [address, connected, setWalletAddress, network]);


  useEffect(() => {
    if (walletAddress || wallet_address) {
      addressToUsdt(walletAddress || wallet_address, network).then((balance) => {
        setAmountInvested({ usdt: balance, wow: balance / data.tokenPrice });
      });
    }
  }, [addressToUsdt, data.tokenPrice, walletAddress, network, reloadBalances, wallet_address]);

  useEffect(() => {
    const walletType = localStorage.getItem('walletConnect');
    if (walletAddress && !walletType) {
      fetchUsdtBalance(walletAddress || wallet_address, network).then((balance) => {
        setUsdtBalance(balance);
      });
    }
  }, [fetchUsdtBalance, walletAddress, network, reloadBalances, wallet_address]);

  const onInputChangeHandler = (e) => {
    validateInput(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${API_BASE_URL}/public/presale-offchain-data`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const { data } = await response.json();

        setData({
          ...data,
          totalCapUsdt: data?.totalCapUsdt,
          totalRaisedUsdt: (data?.totalRaisedUsdt).toFixed(2),
          totalCapWow: data?.totalCapWow,
          totalRaisedWow: (data?.totalRaisedWow).toFixed(2),
          minimumPurchaseUsdt: data?.minimumPurchaseUsdt,
          tokenPrice: data?.tokenPrice,
        });
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const savedNetwork = localStorage.getItem("network") || "ethereum";
  useEffect(() => {
    setNetwork(savedNetwork);
    localStorage.setItem("network", savedNetwork);
  }, [savedNetwork]);

  const handleNetworkChange = async (e) => {
    const selectedNetwork = e.target.value;
    const walletType = localStorage.getItem('walletConnect');
    if (network === selectedNetwork) return;

    // 1) If user was on Tron and is now switching to BSC/Ethereum, disconnect Tron
    if (network === "tron" && (selectedNetwork === "bsc" || selectedNetwork === "ethereum")) {
      try {
        disconnect();
        localStorage.removeItem("walletAddress");
      } catch (err) {
        console.error("Failed to disconnect Tron:", err);
      }
      setUsdtBalance(0);
      setWalletAddress(null);
    }

    // 2) If user was on BSC/Ethereum and is now switching to Tron, disconnect EVM
    if ((network === "bsc" || network === "ethereum") && selectedNetwork === "tron") {
      try {
        localStorage.removeItem("walletAddress");
      } catch (err) {
        console.error("Failed to disconnect EVM wallet:", err);
      }
      setUsdtBalance(0);
      setWalletAddress(null);
    }

    // 3) Only switch EVM networks if it’s "bsc" or "ethereum"
    if ((selectedNetwork === "bsc" || selectedNetwork === "ethereum") && !walletType) {
      await switchNetwork(selectedNetwork);
    } else if (walletType) {
      switchNetworkWalletConnect()
    }

    setNetwork(selectedNetwork);
    localStorage.setItem("network", selectedNetwork);
  };

  const maxClickHandler = () => {
    if (walletAddress || wallet_address) {
      setError("");
      setUsdtValue(usdtBalance);
      setWowValue((usdtBalance / data.tokenPrice).toFixed(3));
    } else {
      setError("Wallet Not Connected");
    }
  };

  const onBuyClick = () => {
    if (!usdtValue) {
      setError("Please enter the USDT amount");
      return false;
    }
    const walletType = localStorage.getItem('walletConnect');
    setLoading(true);
    buyTokens(usdtValue, proof).then(async (res) => {
      if (res?.status) {
        toast.success("Tokens purchased successfully");
        setData({
          ...data,
          totalRaisedUsdt: Number(data.totalRaisedUsdt) + Number(usdtValue),
          totalRaisedWow: Number(data.totalRaisedWow) + Number(wowValue),
        });
        setReloadBalances((prev) => !prev);
      } else {
        if(!walletType){
          toast.error(res?.message.substring(0, 50) + "...");
        }
      }
      setLoading(false);
    });
  };

  const validateInput = (usdtValue) => {
    setError("");
    setUsdtValue(usdtValue);
    if (data?.tokenPrice) {
      setWowValue((usdtValue / data?.tokenPrice).toFixed(3));
      if (isNaN(usdtValue)) {
        setError("Invalid input. Please enter a valid number for USDT amount");
        return false;
      }
      if (walletAddress) {
        if (!usdtValue) {
          setError("Please enter the USDT amount");
          return false;
        }
        if (Number(usdtBalance) < Number(usdtValue)) {
          setError("Insufficient USDT balance");
          return false;
        }
        if (usdtValue < data?.minimumPurchaseUsdt) {
          setError(
            `Amount must be greater than ${data?.minimumPurchaseUsdt} USDT`
          );
          return false;
        }
      }
    }
    return true;
  };

  return (
    <React.Fragment>
      <div className="text-center">
        <div className="main-container">
          <div className="flex lg:flex-nowrap flex-wrap w-full gap-7">
            <div className="profile-section rounded-xl w-full">
              <div className="intro-wrapper">
                <div className="token-details">
                  <div className="flex justify-between gap-5 my-6 flex-wrap lg:flex-nowrap">
                    <div className="rounded-2xl p-2 w-full">
                      <div className="bg-black py-5 px-5 relative rounded-xl">
                        <div className="absolute top-2 right-4">
                          <a href="https://wow-presale.ourwebprojects.pro/#faqs]">
                            <HelpIcon />
                          </a>
                        </div>
                        <div className="text-xl flex justify-center gap-1 font-bold text-center text-white">
                          1 USDT ={" "}
                          <img src={logo} className="w-5" alt="Media" />{" "}
                          {data.tokenPrice ? 1 / data?.tokenPrice : 0}
                        </div>

                        <div className="flex justify-between text-sm md:text-base font-bold text-white">
                          <div>Tokens Sold</div>
                          <div>{`${data?.totalRaisedWow} / ${data?.totalCapWow
                            } (${data?.totalRaisedWow && data?.totalCapWow
                              ? (
                                (data?.totalRaisedWow / data?.totalCapWow) *
                                100
                              ).toFixed(3)
                              : 0
                            }%)`}</div>
                        </div>

                        <div className="progress w-full mt-3">
                          <div
                            className="progress-bar text-sm md:text-base font-semibold"
                            role="progressbar"
                            style={{
                              width:
                                (
                                  (data?.totalRaisedUsdt / data?.totalCapUsdt) *
                                  100
                                ).toString() + "%" || "0%",
                            }}
                            aria-valuenow={88}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            <div className="progress-percentage">
                              USDT Raised: &nbsp;{" "}
                              <span className="text-[#1a7b1e]">{`${data?.totalRaisedUsdt || 0
                                } / ${data?.totalCapUsdt || 0}`}</span>
                            </div>
                          </div>
                        </div>

                        <div className="mt-3 mb-2">
                          <p className="mb-1 text-[18px] text-white font-semibold text-left">
                            Network
                          </p>
                          <div className="radio-button-container">
                            <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="ethereum"
                                name="network"
                                value="ethereum"
                                onChange={handleNetworkChange}
                                checked={network === "ethereum"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="ethereum"
                              >
                                <span className="radio-button__custom" />
                                ETH
                              </label>
                            </div>
                            <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="bsc"
                                name="network"
                                value="bsc"
                                onChange={handleNetworkChange}
                                checked={network === "bsc"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="bsc"
                              >
                                <span className="radio-button__custom" />
                                BSC
                              </label>
                            </div>
                            <div className="radio-button">
                              <input
                                type="radio"
                                className="radio-button__input"
                                id="tron"
                                name="network"
                                value="tron"
                                onChange={handleNetworkChange}
                                checked={network === "tron"}
                              />
                              <label
                                className="radio-button__label"
                                htmlFor="tron"
                              >
                                <span className="radio-button__custom" />
                                TRON
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="w-full">
                          <div className="group fields w-full">
                            <div className="relative w-full flex flex-col">
                              <Input
                                label={"Enter USDT Amount to Invest"}
                                name={"ethValue"}
                                placeholder={`Minimum: ${data.minimumPurchaseUsdt} USDT`}
                                onChange={onInputChangeHandler}
                                hasButton={true}
                                buttonClickHandler={maxClickHandler}
                                value={usdtValue}
                                errorMessage={error}
                              />
                            </div>

                            <div className="flex justify-between items-center pt-2">
                              <p className="text-sm font-bold text-white">
                                USDT
                              </p>
                              <p className="text-base font-bold text-white flex gap-1 items-center">
                                <img src={usdt} className="w-5" alt="Media" />
                                {usdtBalance}
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="mt-2">
                          <div className="flex w-full items-start justify-between">
                            <p className="text-left text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              <span className="text-wow">WOW</span> Tokens
                              You&#39;ll Receive:
                            </p>
                            <p className="flex justify-end gap-1 text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
                              <img src={logo} className="w-5" alt="Media" />
                              {wowValue}
                            </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] flex gap-2 items-center text-white capitalize">
                              Min buy
                              <p className="cursor-pointer" title="Min Buy">
                                <InfoIcon />
                              </p>
                            </p>
                            <p className="text-[18px] flex items-center gap-1 text-white">
                              <img src={usdt} className="w-5 h-5" alt="Media" />{" "}
                              {data.minimumPurchaseUsdt}
                            </p>
                          </div>
                        </div>

                        <div className="details py-[10px] border-b border-dashed border-flash">
                          <div className="flex justify-between items-center">
                            <p className="text-[18px] flex gap-2 items-center text-left text-white capitalize">
                              Your Current Balance
                              <p
                                className="cursor-pointer"
                                title="WOW balance you&#39;ve earned so far"
                              >
                                <InfoIcon />
                              </p>
                            </p>
                            <p className="text-[18px] flex whitespace-nowrap text-end items-center gap-1 text-white">
                              <span className="flex items-center">
                                {`($${amountInvested.usdt}) ${amountInvested.wow} WOW`}
                              </span>
                            </p>
                          </div>
                        </div>

                        <div className="details pt-[10px]">
                          {(walletAddress || wallet_address) && (
                            <div className="flex justify-between items-center">
                              <p className="text-[18px] text-left text-white capitalize">
                                Wallet Address
                              </p>
                              <p className="text-[18px] flex whitespace-nowrap text-end items-center gap-2 text-white">
                                <span className="text-wow">
                                  <MiddleTruncate
                                    text={walletAddress || wallet_address}
                                    maxLength={12}
                                  />
                                </span>
                                <button className="px-2 py-1 rounded-md bg-primary-gradient hover:bg-hover-gradient">
                                  <LogoutIcon
                                    disconnectWallet={() => {
                                      setIsAddressWhitelisted(true);
                                      setUsdtBalance(0);
                                      disconnectWalletConnect();
                                      disconnectWallet();
                                    }}
                                  />
                                </button>
                              </p>
                            </div>
                          )}
                        </div>
                        <div>
                          <span className="text-yellow-400 text-sm">
                            {!isAddressWhitelisted &&
                              "Address not found in whitelist"}
                          </span>
                        </div>

                        <div className="">
                          {(walletAddress || wallet_address) ? (
                            <button
                              disabled={
                                error || loading || !isAddressWhitelisted
                              }
                              onClick={() => onBuyClick()}
                              className={`p-3 ${error || loading || !isAddressWhitelisted
                                ? "bg-gray-500"
                                : "bg-hover-gradient"
                                }  ${!error &&
                                !loading &&
                                isAddressWhitelisted &&
                                "hover:shadow-pump"
                                } min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white`}
                            >
                              Buy with USDT
                            </button>
                          ) : (
                            <button
                              onClick={() => setShowWalletModal(true)}
                              className="p-3 bg-hover-gradient hover:shadow-pump min-h-11 rounded-xl w-full mt-6 text-base font-bold text-white"
                            >
                              CONNECT WALLET
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showWalletModal && (
        <WalletModal
          setShowWalletModal={setShowWalletModal}
          connectMetamask={async () => await connectMetamask(network)}
        />
      )}
    </React.Fragment>
  );
}
