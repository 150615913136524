import React from "react";
export const Input = ({
  label,
  name,
  type,
  placeholder,
  value = "" || 0,
  onChange = () => { },
  errorMessage = "",
  hasButton = false,
  buttonClickHandler = () => { },
}) => {
  return (
    <div className="flex flex-col w-full">
      <label className="inline-block text-left pb-1 text-white w-full text-[18px] font-semibold transition-all duration-200 ease-in-out group-focus-within:text-wow">
        {label}
      </label>
      <input
        type={type}
        name={name}
        placeholder={placeholder}
        className="relative h-[50px] w-full text-base font-bold text-white rounded-lg border bg-black border-gray pr-[100px] pl-4 outline-none drop-shadow-sm transition-all duration-200 ease-in-out focus:bg-gray-900 focus:ring-2 focus:ring-wow focus:border-transparent focus:drop-shadow-lg"
        value={value}
        onChange={onChange}
      />
      {hasButton && (
        <span className="absolute top-9 md:top-[39px] right-2 transition-all duration-200 ease-in-out group-focus-within:text-wow">
          <button
            onClick={buttonClickHandler}
            className="text-base rounded bg-hover-gradient hover:bg-primary-gradient py-[5px] px-4 font-bold uppercase text-white"
          >
            Max
          </button>
        </span>
      )}
      <span className="text-red-500 mt-2 text-sm text-left w-full">
        {errorMessage}
      </span>
    </div>
  );
};
