import { useState, useEffect } from "react";
import { BrowserProvider, ethers } from "ethers";
import { BASE_SEPOLIA_CONFIG, BSC_TESTNET_CONFIG } from "../utils/config";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useAccount, useBalance, useDisconnect } from "wagmi";

export const useWalletMetaMask = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [ethBalance, setEthBalance] = useState(0);

  const { address: tronAddress, disconnect: tronDisconnect } = useWallet();

  const { disconnect } = useDisconnect();
  const { isConnected, address: wallet_address } = useAccount();

  useEffect(() => {
    const network = localStorage.getItem("network");
    if (network === "tron" && tronAddress) {
      setWalletAddress(tronAddress);
    } else if ((network === 'ethereum' || network === 'bsc') && wallet_address){
      setWalletAddress(wallet_address);
    }
  }, [tronAddress, wallet_address]);

  const connectMetamask = async (network) => {
    if (window.ethereum) {
      try {
        const currentNetwork =
          network === "bsc" ? BSC_TESTNET_CONFIG : BASE_SEPOLIA_CONFIG;

        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [currentNetwork],
        });

        const provider = new BrowserProvider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        const newSigner = await provider.getSigner();
        setSigner(newSigner);

        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });

        setWalletAddress(accounts[0]);
        localStorage.setItem("walletAddress", accounts[0]);
        setProvider(provider);
        let walletBalance = ethers.formatEther(
          await provider.getBalance(accounts[0])
        );
        setEthBalance(Number(walletBalance).toFixed(4));
      
        const { chainId } = await provider.getNetwork();
      
        const parsedChainId = parseInt(chainId, 10);
        if (parsedChainId === 97) {
          localStorage.setItem("network", "bsc");
        } else if (parsedChainId === 56) {
          localStorage.setItem("network", "bsc");
        } else if (parsedChainId === 84532) {
          localStorage.setItem("network", "ethereum");
        } else {
          localStorage.setItem("network", "ethereum");
        }
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
  } else {
    alert("MetaMask not found. Please install MetaMask.");
  }
};

  const refreshBalance = async (account) => {
    if (provider && account) {
      const balance = await provider.getBalance(account);
      setEthBalance(Number(ethers.formatEther(balance)).toFixed(4));
    }
  };

  const switchNetwork = async (selectedNetwork) => {
    try {
      const currentNetwork =
        selectedNetwork === "bsc" ? BSC_TESTNET_CONFIG : BASE_SEPOLIA_CONFIG;
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [currentNetwork],
        });
      } else {
        return { error: "MetaMask not found. Please install MetaMask." };
      }
    } catch (err) {
      console.error("Network switch error:", err);
    }
  };

  const disconnectWallet = async () => {
    const network = localStorage.getItem("network");
    setWalletAddress(null);
    setProvider(null);
    setSigner(null);
    setEthBalance(0);
    localStorage.removeItem("walletAddress");
    if (network === "tron") {
      try {
        await tronDisconnect();
      } catch (error) {
        console.error("Error disconnecting Tron wallet:", error);
      }
    } else if (network === "bsc" || network === "ethereum") {
      disconnect();
    }
  };

  useEffect(() => {
    const savedWalletAddress = localStorage.getItem("walletAddress");

    if (window.ethereum && savedWalletAddress) {
      window.ethereum.on("accountsChanged", async (accounts) => {
        if (accounts.length > 0) {
          if (provider) {
            const newSigner = await provider.getSigner();
            setSigner(newSigner);
            let walletBalance = ethers.formatEther(
              await provider.getBalance(accounts[0])
            );
            setEthBalance(Number(walletBalance).toFixed(4));
          }
          setWalletAddress(accounts[0]);
          localStorage.setItem("walletAddress", accounts[0]);
        } else {
          setWalletAddress(null);
        }
      });
      window.ethereum.on("chainChanged", () => {
        window.location.reload();
      });
    }
  }, [provider]);

  return {
    walletAddress,
    ethBalance,
    setEthBalance,
    provider,
    connectMetamask,
    signer,
    disconnectWallet,
    refreshBalance,
    switchNetwork,
    setWalletAddress
  };
};
