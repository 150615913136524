"use client";
import { http } from "wagmi";
import {
  connectorsForWallets,
  getDefaultConfig,
  getWalletConnectConnector,
} from "@rainbow-me/rainbowkit";
import { baseSepolia, bsc, bscTestnet, mainnet, sepolia } from "wagmi/chains";
import {
  injectedWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";

const projectId = "c9303d447e58d4f4156c7c8ab0ce7e31";
const wowEarnWallet = ({ projectId }) => ({
  id: "wow-earn-wallet",
  name: "WOW EARN Wallet",
  iconUrl:
    "https://media.licdn.com/dms/image/D4D0BAQGeS9ozbxH1iw/company-logo_200_200/0/1710776030038/wow_earn_logo?e=2147483647&v=beta&t=tKh7o3CkO0ueVmWMYOPlM7U0hWla1Y4QySRVEE7KnTE",
  iconBackground: "#0c2f78",
  downloadUrls: {
    android:
      "https://play.google.com/store/apps/dev?id=8086605849401192032&hl=en_US",
    ios: "https://apps.apple.com/us/app/wow-earn-btc-crypto-wallet/id6443434220",
  },
  mobile: {
    getUri: (uri) => uri,
    // getUri: (uri) => `ullawallet://wc?uri=${encodeURIComponent(uri)}`,
  },
  qrCode: {
    getUri: (uri) => uri,
    // getUri: (uri) => `ullawallet://wc?uri=${(uri)}`,
    instructions: {
      learnMoreUrl: "https://my-wallet/learn-more",
      steps: [
        {
          description:
            "We recommend putting My Wallet on your home screen for faster access to your wallet.",
          step: "install",
          title: "Open the My Wallet app",
        },
        {
          description:
            "After you scan, a connection prompt will appear for you to connect your wallet.",
          step: "scan",
          title: "Tap the scan button",
        },
      ],
    },
  },
  extension: {
    instructions: {
      learnMoreUrl: "https://my-wallet/learn-more",
      steps: [
        {
          description:
            "We recommend pinning My Wallet to your taskbar for quicker access to your wallet.",
          step: "install",
          title: "Install the My Wallet extension",
        },
        {
          description:
            "Be sure to back up your wallet using a secure method. Never share your secret phrase with anyone.",
          step: "create",
          title: "Create or Import a Wallet",
        },
        {
          description:
            "Once you set up your wallet, click below to refresh the browser and load up the extension.",
          step: "refresh",
          title: "Refresh your browser",
        },
      ],
    },
  },
  createConnector: getWalletConnectConnector({ projectId }),
});

const connectors = connectorsForWallets(
  [
    {
      groupName: "Recommended",
      wallets: [wowEarnWallet, walletConnectWallet, injectedWallet],
    },
  ],
  {
    appName: "WOW EARN App",
    projectId: projectId,
  }
);

export const config = getDefaultConfig({
  appName: "WOW EARN App",
  projectId,
  autoConnect: true,
  chains: [ bsc, bscTestnet, baseSepolia, sepolia, mainnet],
  connectors: connectors,
  transports: {
    [bsc.id]: http(),
    [sepolia.id]: http(),
    [bscTestnet.id]: http('https://bsc-testnet.public.blastapi.io'),
    [mainnet.id]: http(),
    [baseSepolia.id]: http('https://sepolia.base.org'),
  },
});
