import erc20Abi from "../web3/erc20.json";
import presaleContractAbi from "../web3/presale.json";
import vestingContractAbi from "../web3/vesting.json";

export const CONTRACT_ADDRESSES = {
  bsc: {
    DECIMALS: 18,
    WOW_TOKEN: "0x181Af90a5dB66Bd5B8cB6EF9A4e0463Dc877bd7b",
    USDT_TOKEN: "0x96c8Ed4C38E790048502376b253dd8F7B32C75dD",
    PRESALE_CONTRACT: "0xB03Ac19372e4ed77e4228FcB0Ecb724eE67cA320",
    VESTING_CONTRACT: "0xE585bbc6774491bcbf18d33066aA597602F5E7b2",
  },
  ethereum: {
    DECIMALS: 18,
    WOW_TOKEN: "0xe24A6b49574eA20331a983cB4f3D7D91770a4400",
    USDT_TOKEN: "0xEB2DbE23F0c18C5c78762e74ecF634e54D3BFb04",
    PRESALE_CONTRACT: "0xB02e9b9138335F7CE5f68E3DE33EE97e59D9D52b",
    VESTING_CONTRACT: "0xC315Fd58322C881237eFE3556280dA2e33616962",
  },
  tron: {
    DECIMALS: 18,
    WOW_TOKEN: "TBd6dDYsRqbvivzTwfF3m8fHDg2J1S5TGS",
    USDT_TOKEN: "TNuXiZ5Qy1C8ixhYnBRycedgtN1QAs87gs",
    PRESALE_CONTRACT: "TYH9K8H3ys9B78s46HV3SSLzfmBda1PbuC",
    VESTING_CONTRACT: "TM8LT8otX1oKz2MKb4NKvpN9f9xdJcZTiZ",
  },
};

export const API_BASE_URL = "https://api.dev.presale.evolalabs.com/api/";

export const ABIS = {
  ERC20_CONTRACT_ABI: erc20Abi,
  PRESALE_CONTRACT_ABI: presaleContractAbi,
  VESTING_CONTRACT_ABI: vestingContractAbi,
};

export const PRESALE_START_DATE = 1730419200000; // Friday, November 1, 2024 12:00:00 AM
export const PRESALE_END_DATE = 1735689599000; // Tuesday, 31 December 2024 23:59:59 UTC

export const VESTING_START_DATE = 1735689600000; // Wednesday, January 1, 2025 12:00:00 AM
export const VESTING_END_DATE = 1751241600000; // Monday, June 30, 2025 12:00:00 AM
