import TronWeb from 'tronweb';

export const initTronWeb = async () => {
    if (!window.tronWeb) {
        // Initialize TronWeb with default nodes if not already initialized
        const tronWeb = new TronWeb({
            fullHost: 'https://api.trongrid.io',
            // headers: { "TRON-PRO-API-KEY": '46873068-c84a-4782-989e-1c914096182f' }, // Replace with your API key
        });
        window.tronWeb = tronWeb;
    }

    // Wait for protocol initialization
    await window.tronWeb?.ready;
    return window.tronWeb;
};