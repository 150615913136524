import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { useEffect } from "react";
import { useWalletMetaMask } from "../hooks/useWallet";

const TronWalletConnect = ({ handleClose }) => {
  const {
    wallet,
    address,
    connected,
    select,
    connect,
    disconnect,
    signMessage,
    signTransaction,
  } = useWallet();

  const { setWalletAddress } = useWalletMetaMask();
  useEffect(() => {
    if (!wallet) {
      select("TronLink");
    }
  }, [wallet, select]);

  const handleConnect = async () => {
    try {
      await connect();
      localStorage.setItem("network", "tron");
      if (window.tronWeb && window.tronWeb.defaultAddress?.base58) {
        console.log("tron address:", window.tronWeb.defaultAddress.base58);
        localStorage.setItem(
          "walletAddress",
          window.tronWeb.defaultAddress.base58
        );
        setWalletAddress(window.tronWeb.defaultAddress.base58);
      }
      handleClose();
    } catch (error) {
      console.error("Connection error:", error);
    }
  };

  const handleDisconnect = async () => {
    try {
      await disconnect();
    } catch (error) {
      console.error("Disconnection error:", error);
    }
  };

  const handleSignMessage = async () => {
    try {
      const message = "Hello TRON!";
      const signature = await signMessage(message);
      console.log("Signature:", signature);
    } catch (error) {
      console.error("Signing error:", error);
    }
  };

  return (
    <div className="text-white">
      <button
        onClick={handleConnect}
        className="flex mb-3 hover:bg-[#227b52] w-full text-base justify-between items-center bg-[#04594e] rounded-lg py-[14px] px-6"
      >
        <div className="flex items-center gap-4 font-semibold text-white">
          <img
            src="https://lh3.googleusercontent.com/1BPbdzIDWE2RYHDIZV3K--KG2VWgS_R8yjs2ZVLvoVnAhpCMWZPwJGJ2Q5PjXbVXUMj7u6DUh-Z7wQ9vJVf37d-h=s60"
            className="w-10"
            alt="Media"
          />
          <span>Tron Link</span>
        </div>
      </button>
    </div>
  );
};

export default TronWalletConnect;
